var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',[_c('v-card',[_c('v-container',{staticClass:"filter"},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"","sm":"6"}},[_c('DatePicker')],1),_c('v-col',{attrs:{"cols":"","sm":"6"}},[_c('v-text-field',{attrs:{"height":"100%","append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.searchText),callback:function ($$v) {_vm.searchText=$$v},expression:"searchText"}})],1),_c('v-col',{attrs:{"cols":"","sm":"6"}},[_c('v-select',{attrs:{"items":_vm.shareCategories,"label":"Categories","multiple":"","return-object":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index === 0)?_c('v-chip',{attrs:{"small":""}},[_c('span',[_vm._v(_vm._s(item))])]):_vm._e(),(index === 1)?_c('span',{staticClass:"grey--text caption"},[_vm._v("(+"+_vm._s(_vm.selectedCategories.length - 1)+" others)")]):_vm._e()]}}]),model:{value:(_vm.selectedCategories),callback:function ($$v) {_vm.selectedCategories=$$v},expression:"selectedCategories"}})],1),_c('v-col',{attrs:{"cols":"","sm":"6"}},[_c('v-select',{attrs:{"items":_vm.headers,"label":"Columns","multiple":"","return-object":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index === 0)?_c('v-chip',{attrs:{"small":""}},[_c('span',[_vm._v(_vm._s(item.text))])]):_vm._e(),(index === 1)?_c('span',{staticClass:"grey--text caption"},[_vm._v("(+"+_vm._s(_vm.selectedColumns.length - 1)+" others)")]):_vm._e()]}}]),model:{value:(_vm.selectedColumns),callback:function ($$v) {_vm.selectedColumns=$$v},expression:"selectedColumns"}})],1)],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.selectedColumns,"items":_vm.dailyDataFiltered,"multi-sort":"","search":_vm.searchText,"options":_vm.options,"item-class":_vm.itemRowBackground,"loading-text":"Loading... Please wait","fixed-header":"","loading":_vm.tableLoading},on:{"contextmenu:row":_vm.showContextMenu,"dblclick:row":_vm.showContextMenu},scopedSlots:_vm._u([{key:"item.symbol",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('strong',[_vm._v(_vm._s(item.symbol))]),_c('v-icon',[_vm._v("fa-ad")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.name))])])]}},{key:"item.market_price",fn:function(ref){
var item = ref.item;
return [_c('span',[_c('strong',[_vm._v(_vm._s(item.market_price))])])]}},{key:"item.eps",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.eps)+" "+_vm._s(item.eps_quarter))])]}}])})],1),_c('vue-simple-context-menu',{ref:'rightClickMenu',attrs:{"elementId":"rightClickMenuId","options":_vm.contextMenus},on:{"option-clicked":_vm.contexMenuItemClicked}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }