let today = new Date()
today.setUTCHours(0, 0, 0, 0)
let todayStart = today.toISOString()
today.setUTCHours(23, 59, 59, 999)
let todayEnd = today.toISOString()

const state = {
	realmToken : '',
	todayData: [],
	selectedDate: todayStart.split('T')[0],
	selectedDateStart: todayStart,
	selectedDateEnd: todayEnd,
	shareCategories: [],
}

export default (state)