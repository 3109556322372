const getters = {
    getRealmToken(state){
        return state.realmToken
    },
    getTodayData(state) {
        return state.todayData
    },
    getSelectedDate(state) {
        return state.selectedDate
    },
    getSelectedDateStart(state) {
        return state.selectedDateStart
    },
    getSelectedDateEnd(state) {
        return state.selectedDateEnd
    },
    getShareCategories(state) {
        return state.shareCategories
    }
}

export default (getters)