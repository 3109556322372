<template>
    <div>
        <v-container>
            <v-card>
                <v-container class="filter">
                    <v-row align="center">
                        <v-col cols sm="6">
                            <DatePicker />
                        </v-col>
                        <v-col cols sm="6">
                            <v-text-field
                                height="100%"
                                v-model="searchText"
                                append-icon="mdi-magnify"
                                label="Search"
                                single-line
                                hide-details
                            >
                            </v-text-field>
                        </v-col>
                        <v-col cols sm="6">
                            <v-select
                                v-model="selectedCategories"
                                :items="shareCategories"
                                label="Categories"
                                multiple
                                return-object
                            >
                                <template v-slot:selection="{ item, index }">
                                    <v-chip small v-if="index === 0">
                                        <span>{{ item }}</span>
                                    </v-chip>
                                    <span
                                            v-if="index === 1"
                                            class="grey--text caption"
                                    >(+{{
                                            selectedCategories.length - 1
                                        }}
                                        others)</span
                                    >
                                </template>
                            </v-select>
                        </v-col>
                        <v-col cols sm="6">
                            <v-select
                                v-model="selectedColumns"
                                :items="headers"
                                label="Columns"
                                multiple
                                return-object
                            >
                                <template v-slot:selection="{ item, index }">
                                    <v-chip small v-if="index === 0">
                                        <span>{{ item.text }}</span>
                                    </v-chip>
                                    <span
                                        v-if="index === 1"
                                        class="grey--text caption"
                                        >(+{{
                                            selectedColumns.length - 1
                                        }}
                                        others)</span
                                    >
                                </template>
                            </v-select>
                        </v-col>
                    </v-row>
                </v-container>
                <v-data-table
                    :headers="selectedColumns"
                    :items="dailyDataFiltered"
                    multi-sort
                    class="elevation-1"
                    :search="searchText"
                    :options="options"
                    :item-class="itemRowBackground"
                    loading-text="Loading... Please wait"
                    fixed-header
                    :loading="tableLoading"
                    @contextmenu:row="showContextMenu"
                    @dblclick:row="showContextMenu"
                >
                    <template v-slot:item.symbol="{ item }">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <span v-bind="attrs" v-on="on"
                                    ><strong>{{ item.symbol }}</strong><v-icon>fa-ad</v-icon></span
                                >
                            </template>
                            <span>{{ item.name }}</span>
                        </v-tooltip>
                    </template>
                    <template v-slot:item.market_price="{ item }">
                        <span
                            ><strong>{{ item.market_price }}</strong></span
                        >
                    </template>
                    <template v-slot:item.eps="{ item }">
                        <span>{{ item.eps }} {{ item.eps_quarter }}</span>
                    </template>
                </v-data-table>
            </v-card>
            <vue-simple-context-menu
                    elementId="rightClickMenuId"
                    :options="contextMenus"
                    :ref="'rightClickMenu'"
                    @option-clicked="contexMenuItemClicked"
            />
        </v-container>

    </div>
</template>

<script>
    import todayDataStore from '@/store/modules/share-list'
    import {mapGetters} from 'vuex'
    import DatePicker from './DatePicker'
    import VueSimpleContextMenu from 'vue-simple-context-menu'
    import 'vue-simple-context-menu/dist/vue-simple-context-menu.css'

    export default {
        data() {
            return {
                contextMenus: [
                    {
                        name: 'Add to watch list',
                        slug: 'watchList'
                    },
                    {
                        type: 'divider'
                    },
                    {
                        name: 'Sharesansar',
                        slug: 'sharesansar'
                    },
                    {
                        name: 'Merolagani',
                        slug: 'merolagani'
                    }],
                tableLoading: true,
                selectedCategories: [],
                selectedColumns: [],
                options: {
                    itemsPerPage: -1
                },
                searchText: '',
                headers: [
                    {text: 'Company', value: 'symbol',},
                    {text: 'Name', value: 'name', align: ' d-none'},
                    {text: 'Market Price', value: 'market_price'},
                    {text: '% Change', value: 'percentage_change'},
                    {text: 'P/E Ratio', value: 'pe_ratio'},
                    {text: 'EPS', value:'eps'},
                    {text: 'Category', value: 'category', filterable: false},

                ],


            }
        },

        computed: {
            ...mapGetters('todayData', {
                dailyData: 'getTodayData',
                selectedDate: 'getSelectedDate',
                startOfTheDay: 'getSelectedDateStart',
                endOfTheDay: 'getSelectedDateEnd',
                shareCategories: 'getShareCategories',
            }),
            dailyDataFiltered: function () {
                return this.dailyData.filter(data => this.selectedCategories.indexOf(data.category) !== -1);
            },
        },
        beforeCreate() {
            this.$store.registerModule("todayData", todayDataStore);
        },

        async created() {
            await this.$store.dispatch("todayData/getRealmToken")

            await this.getDataOfSpecificDate()
            this.tableLoading = false

            this.selectedColumns = this.headers
            this.selectedCategories = this.shareCategories
        },

        methods: {
            itemRowBackground(item) {
                if (item.percentage_change < 0) return "table-red";
                else if (item.percentage_change == 0) return "table-gray";
                else return "table-green";
            },
            async getDataOfSpecificDate() {
                await this.$store.dispatch("todayData/getTodayData", {
                    startOfTheDay: this.startOfTheDay,
                    endOfTheDay: this.endOfTheDay,
                });
                this.selectedCategories = this.shareCategories
            },
            showContextMenu (event, item) {
                event.preventDefault()
                this.$refs.rightClickMenu.showMenu(event, item.item.symbol)
            },
            contexMenuItemClicked (event) {
                if(event.option.slug == 'sharesansar'){
                    window.open("https://sharesansar.com/company/" + event.item, '_blank')
                }
                if(event.option.slug == 'merolagani'){
                    window.open("https://merolagani.com/CompanyDetail.aspx?symbol=" + event.item, '_blank')
                }
            }
        },
        watch: {
            async selectedDate() {
                this.tableLoading = true;
                await this.getDataOfSpecificDate();
                this.tableLoading = false;
            },
        },
        components: {
            DatePicker,
            'vue-simple-context-menu': VueSimpleContextMenu
        },
    };
</script>
<style>
[class^="col-"] {
    padding-bottom: 0;
}
.filter {
    background: #faf8ff;
}
.table-gray {
    background-color: #d1d5db !important;
    border-color: #d1d5db !important;
}
.table-green {
    background-color: #a7f3d0 !important;
    border-color: #a7f3d0 !important;
}
.table-red {
    background-color: #fca5a5 !important;
    border-color: #fca5a5 !important;
}
.theme--light.v-data-table {
    color: #333333;
}
.v-application .v-data-table.elevation-1 {
    box-shadow: none !important;
}
.v-sheet.v-card {
    box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 20%) !important;
    margin-top: 20px;
}
#rightClickMenuId {
    padding-left: 0px !important;
}
</style>
