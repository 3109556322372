const mutations = {
    setRealmToken(state, value) {
        state.realmToken = value
    },

    setTodayData(state, todayData) {
        state.todayData = todayData
    },

    setSelectedDate(state, selectedDate) {
        state.selectedDate = selectedDate
        let today = new Date(selectedDate);
        today.setUTCHours(0, 0, 0, 0)
        state.selectedDateStart = today.toISOString()
        today.setUTCHours(23, 59, 59, 999)
        state.selectedDateEnd = today.toISOString()
    },

    setShareCategories(state, shareData) {
        let category = [];
        for (const data of shareData){
            if(category.indexOf(data.category) === -1) {
                category.push(data.category)
            }
        }
        state.shareCategories = category
    }
}

export default (mutations)