<template>
    <v-dialog ref="dialog"
              v-model="showDatePicker"
              :return-value.sync="selectedDate"
              persistent
              width="290px">
        <template v-slot:activator="{ on, attrs }">
            <v-text-field
                    v-model="selectedDate"
                    label="Select Date"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on">
            </v-text-field>
        </template>
        <v-date-picker
                v-model="selectedDate"
                scrollable
                @input="$refs.dialog.save(selectedDate)">
            <v-spacer></v-spacer>
            <v-btn
                    text
                    color="primary"
                    @click="showDatePicker = false">
                Cancel
            </v-btn>
            <v-btn
                    text
                    color="primary"
                    @click="$refs.dialog.save(selectedDate)">
                OK
            </v-btn>
        </v-date-picker>
    </v-dialog>
</template>

<script>
    export default {
        data() {
            return{
                showDatePicker : false,
            }
        },
        computed: {
            selectedDate: {
                get(){
                    return this.$store.getters['todayData/getSelectedDate']
                },
                set(value){
                    this.$store.commit('todayData/setSelectedDate', value)
                }
            }
        }
    }
</script>