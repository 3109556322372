<template>
  <v-app>
<!--    <v-app-bar-->
<!--      app-->
<!--      color="primary"-->
<!--      dark-->
<!--    >-->
<!--      <div class="d-flex align-center">-->
<!--        <span class="mr-2">Hello NEPSE</span>-->
<!--      </div>-->

<!--      <v-spacer></v-spacer>-->


<!--    </v-app-bar>-->

    <v-main>
      <DailyData/>
    </v-main>
  </v-app>
</template>

<script>
import DailyData from './components/DailyData';

export default {
  name: 'App',

  components: {
    DailyData,
  },

  data: () => ({
    //
  }),
};
</script>
